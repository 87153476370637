import {atomWithStorage} from "jotai/utils";
import {useAtomValue} from "jotai";
import {IntlProvider} from "react-intl";
import sv from "../lang/compiled/sv";
import {defaultLanguage} from "./Config";

const locales = {
    // NOTE: English gets set via defaultMessage in the code. If we start using formats
    "en": {},
    "sv": sv,
}

export const localeAtom = atomWithStorage("locale", defaultLanguage)

export default function LocaleProvider ({children}) {
    const locale = useAtomValue(localeAtom)
    const messages = locales[locale] || locales[defaultLanguage]
    return (
        <IntlProvider locale={locale}
                      messages={messages}
                      defaultRichTextElements={{
                          span: (chunks) => <span className="inline-block">{chunks}</span>
                      }}
                      defaultLocale="en" >
            {children}
        </IntlProvider>
    )
}