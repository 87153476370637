
/* Define all configuration variables */

export const isDevEnvironment = process.env.NODE_ENV === "development"
export const appBuildVersion = process.env.REACT_APP_BUILD_VERSION || "development"
export const appTarget = process.env.REACT_APP_TARGET || "staging"
export let apiServerUrl = process.env.REACT_APP_SERVER_URL
export let defaultLanguage = "sv"
export const leagues = ["allsvenskan", "superettan"]
export const defaultLeague = leagues[0]
export const availableSeasons = ["2024", "2023", "2022", "2021", "2020", "2019", "2018", "2017"]
export let defaultSeason = availableSeasons[0]
export const queryCacheStaleTime = 15 * 60 * 1000
export const minimumStarRatingToShowInTimeline = 2
export const highlightsPlusMinRating = 4
export let ssoCheckoutUrl = "https://sef-checkout-experiments.web.app/"
export let ssoMyPageUrl = "https://minasidor-tst.svenskelitfotboll.se/"
export const sentryDSN = "https://06a3381fb2df49adb00c79fe46ff0a9b@o543475.ingest.sentry.io/6547807"
export let rudderstackKey = null
export let rudderstackPlaneUrl = "https://svenskelit-dataplane.rudderstack.com"
export let rudderstackEnableVideoEvents = false
export let showDebugColorPicker = false
export let prerolls = {
    "allsvenskan": "13187:0:3000_",
    "superettan": "13186:0:3000_",
}

/* Update / provide defaults depending on what deployment we're doing */

if (isDevEnvironment) {
    // Dev
    // apiServerUrl = apiServerUrl || "http://localhost:8080/"
    apiServerUrl = apiServerUrl || "https://staging-api.forzasys.com/"
    // apiServerUrl = apiServerUrl || "https://api.forzify.com/"
    showDebugColorPicker = true
    // defaultLanguage = "en"
    // rudderstackKey = "2Tmrf7l0QMELxdH4sYZwCihUrZv" // TODO Temporary while developing
    prerolls = {
        "allsvenskan": "7869:0:3000_",
        "superettan": "7868:0:3000_",
    }

}  else if (appTarget === "staging") {

    // apiServerUrl = apiServerUrl || "https://staging-api.forzasys.com/"
    apiServerUrl = apiServerUrl || "https://staging-api.forzify.com/"
    showDebugColorPicker = true
    rudderstackKey = null // temporarily disabled
    prerolls = {
        "allsvenskan": "7869:0:3000_",
        "superettan": "7868:0:3000_",
    }
    // staging doesn't have latest data yet for 2024
    defaultSeason = availableSeasons[1]

} else {

    // apiServerUrl = apiServerUrl || "https://api.forzasys.com/"
    apiServerUrl = apiServerUrl || "https://api.fotbollplay.se/"
    ssoCheckoutUrl = "https://checkout.svenskelitfotboll.se/"
    ssoMyPageUrl = "https://minasidor.svenskelitfotboll.se/"
    rudderstackKey = "2Tmrf7l0QMELxdH4sYZwCihUrZv"
}


/* Export everything as an object too, if we prefer using Config.xyz */

const Config = {
    isDevEnvironment,
    appBuildVersion,
    appTarget,
    apiServerUrl,
    defaultLanguage,
    leagues,
    defaultLeague,
    availableSeasons,
    defaultSeason,
    queryCacheStaleTime,
    minimumStarRatingToShowInTimeline,
    highlightsPlusMinRating,
    ssoCheckoutUrl,
    sentryDSN,
    prerolls,
    rudderstackKey,
    rudderstackPlaneUrl,
    rudderstackEnableVideoEvents,
    showDebugColorPicker,
}
export default Config