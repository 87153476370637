import * as Sentry from "@sentry/react";
import * as rudderanalytics from "rudder-sdk-js";
import { Integrations } from "@sentry/tracing";
import {
    appTarget,
    isDevEnvironment,
    rudderstackEnableVideoEvents,
    rudderstackKey,
    rudderstackPlaneUrl,
    sentryDSN
} from "./Config";
import {getApiPath} from "./getApiPath";

const track = window.track

function initializeSentry () {
    const environment = appTarget === "staging" ? "staging" : "production"
    Sentry.init({
        dsn: sentryDSN,
        integrations: [new Integrations.BrowserTracing()],
        environment: environment,
        // Set tracesSampleRate to 1.0 to capture 100% for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        ignoreErrors: [
            "ChunkLoadError",
            "TypeError: Failed to fetch",
        ],
    })
}

function initializeRudderAnalytics () {
    if (!rudderstackKey) return
    const logLevel = isDevEnvironment ? "DEBUG" : "WARN"
    rudderanalytics.load(rudderstackKey, rudderstackPlaneUrl, {logLevel})
}

export function initializeAnalytics () {
    if (!isDevEnvironment) {
        initializeSentry()
    }
    initializeRudderAnalytics()

    track?.load("https://events.forzasys.com")
}

// ---------- Handler functions -----------

export function forzifyAnalytics (...args) {
    console.log("analytics", ...args)
    if (!isDevEnvironment) track?.track(...args)
}

export function registerUserChanged (user=null) {
    if (!user) {
        return rudderanalytics.reset()
    } else {
        return rudderanalytics.identify(user.fotbollkonto_id, {
            username: user.fotbollkonto_unique_name,
        })
    }
}

export function rudderTrack (event, properties) {
    if (!rudderstackKey) return
    if (!rudderstackEnableVideoEvents) return
    console.log("rudder analytics", event, properties)
    return rudderanalytics.track(event, properties)
}

export function rudderPage (path) {
    if (!rudderstackKey) return
    rudderanalytics.page(path)
}

export function registerPlaylistView (
    playlist,
    userId=undefined,
    appName="fotbollplay",
    engine="flowplayer") {

    const {id, is_live, events} = playlist
    const contentSource = events?.length > 0 ? events[0].content_source : undefined

    forzifyAnalytics("view", {
        engine: engine,
        playlist: id,
        content_source: contentSource,
        app_name: appName,
        is_live: is_live,
        user: userId,
    });

    const url = getApiPath(`/playlist/${id}/view`)
    fetch(url, {mode: "cors", method: "GET"}).then(r => false)
}

export function createPlaybackAnalyticsSession (playlist, userId) {
    /* Returns a function that should be called with playback location in seconds on time update */

    let lastTrackedPosition = 0
    let totalDurationPlayed = 0
    let durationToSend = 0
    let viewSent = false

    return (seconds, isSeeking) => {

        const diff = seconds - lastTrackedPosition
        lastTrackedPosition = seconds

        // We assume this to be seeking, or invalid data, so we ignore it
        if (isSeeking || diff > 1 || diff < 0) return

        durationToSend += diff
        totalDurationPlayed += diff

        if (!viewSent && totalDurationPlayed > 5) {
            registerPlaylistView(playlist, userId)
            viewSent = true
        }

        // We send fewer and fewer events the longer the user watches, just as a performance optimization
        // For long streams, we don't need to send an event as often - missing an event won't be that bad
        let trackFrequency = 2
        if (totalDurationPlayed > 180) trackFrequency = 32
        else if (totalDurationPlayed > 60) trackFrequency = 16
        else if (totalDurationPlayed > 20) trackFrequency = 8
        else if (totalDurationPlayed > 4) trackFrequency = 4

        if (durationToSend < trackFrequency) return

        const {id, is_live, events} = playlist
        const contentSource = events?.length > 0 ? events[0].content_source : undefined

        forzifyAnalytics("duration", {
            duration: durationToSend,
            engine: "flowplayer",
            playlist: id,
            content_source: contentSource,
            app_name: "fotbollplay",
            is_live: is_live,
            user: userId,
        })
        durationToSend = 0
    }
}