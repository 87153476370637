import React, {Suspense} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";
import {QueryClientProvider} from "react-query";
import {queryClient} from "./hooks/forzifyApi";
import LocaleProvider from "./lib/locale";

// We try to reduce the bundle size as much as possible for the embedded page
const MainRoutes = React.lazy(() => import("./MainRoutes"))
const EmbedPage = React.lazy(() => import("./pages/Embed/EmbedPage"))

function App () {

    return (
        <LocaleProvider>
            <QueryClientProvider client={queryClient}>
                <HelmetProvider>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/embed/playlist/:playlistId"
                                   element={<Suspense><EmbedPage /></Suspense>} />
                            <Route index path="/*"
                                   element={<Suspense><MainRoutes /></Suspense>} />
                        </Routes>
                    </BrowserRouter>
                </HelmetProvider>
            </QueryClientProvider>
        </LocaleProvider>
    )
}

export default App;
