import stringify from "fast-json-stable-stringify";
import {apiServerUrl, defaultLeague} from "./Config";

export function getApiPath (path, query = undefined, league = undefined) {
    query = query || {}
    if (league === undefined) query = {all_leagues: true, ...query}
    if (!path.startsWith("/")) path = "/" + path

    const url = new URL(apiServerUrl + (league || defaultLeague) + path)

    // It's important to add query parameters in sorted order to get an identical cache key
    let queryKeys = Object.keys(query).filter(key => query[key] !== undefined && query[key] !== null)
    queryKeys.sort()
    queryKeys.forEach(key => {
        let value = query[key]
        if (value instanceof Object) value = stringify(value)
        url.searchParams.set(key, value)
    })

    return url.toString()
}